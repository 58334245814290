









































import KHamburgerMenu from '@/@core/components/navigation/KHamburgerMenu.vue';
import KCard from '@/@core/components/other/KCard.vue';
import KWrapper from '@/@core/components/other/KWrapper.vue';
import KPage from '@/@core/components/page/KPage.vue';
import KPageBody from '@/@core/components/page/KPageBody.vue';
import KPageHeader from '@/@core/components/page/KPageHeader.vue';
import KText from '@/@core/components/typography/KText.vue';
import useTableOptions from '@/@core/helpers/table-options';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import { useAuthStore } from '@/modules/auth/services/store';
import { useIndustryStore } from '@/modules/industry/services/store';
import { useProductStore } from '@/modules/product/services/store';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  unref,
  watch
} from '@vue/composition-api';
import EmployeeDashboard from '../../ui/EmployeeDashboard.vue';
import StatisticCard from '../../ui/StatisticCard.vue';
import StatisticChart from '../../ui/StatisticChart.vue';
import VerifierDashboard from '../../ui/VerifierDashboard.vue';
import DistrictFilter from '@/modules/industry/ui/filters/district-filter.vue';
import VillageFilter from '@/modules/industry/ui/filters/village-filter.vue';
import { IS_INSTALLED} from "@/@core/helpers/is-installed"
import KInstallButton from '@/@core/components/button/KInstallButton.vue';
import { useUiStateStore } from '@/@core/services/uiStateStore';

export default defineComponent({
  components: {
    KPage,
    KPageHeader,
    KText,
    KPageBody,
    KWrapper,
    KCard,
    KHamburgerMenu,
    StatisticCard,
    StatisticChart,
    EmployeeDashboard,
    VerifierDashboard,
    DistrictFilter,
    VillageFilter,
    KInstallButton
},
  name: 'LandingDefaultPage',
  setup() {
    const statistics = ref([
      {
        icon: 'mdi-city-variant-outline',
        title: '301 Industri',
        text: 'Industri yang terdaftar'
      },
      {
        icon: 'mdi-city-variant-outline',
        title: '71 Industri Mikro',
        text: 'Investasi < 1M'
      },
      {
        icon: 'mdi-city-variant-outline',
        title: '156 Industri Kecil',
        text: 'Investasi 1M - 15M'
      },
      {
        icon: 'mdi-city-variant-outline',
        title: '74 Industri Menengah',
        text: 'Investasi > 15M'
      }
    ]);

    const isLoading = ref(false);
    const uiStore = useUiStateStore()
    const authStore = useAuthStore();
    const industryStore = useIndustryStore();
    const productStore = useProductStore();

    const isAdmin = computed(() => authStore.role == 'ADMIN');
    const isVerifier = computed(() => authStore.role == 'VERIFIER');
    const isEmployee = computed(() => authStore.role == 'EMPLOYEE');

    const filter = ref({
      district: 'Semua',
      village: 'Semua'
    })

    const getData = async() => {

      isLoading.value = true;

      const usedFilter = {...unref(filter)}

      await industryStore.getStatisticIndustry({
        filter: unref(usedFilter),
        options: useTableOptions(),
      })

      isLoading.value = false;
    }

    watch(() => filter, async () => {
      await getData()
    }, { immediate: true, deep: true})

    onMounted(async () => {
        if (unref(isEmployee)) filter.value.district = authStore.user?.district?.name
    });

    return {
      isMobile,
      statistics,
      authStore,
      industryStore,
      isLoading,
      isAdmin,
      isVerifier,
      isEmployee,
      productStore,
      filter,
      IS_INSTALLED,
      uiStore
    };
  }
});
